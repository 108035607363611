// This file is generated by app/lib/prepare_javascript_config.rb
export default {
  MESSAGE_THREADS: { MAX_VISIBLE_INVITES: 7, VISIBILITY_RENDER_DELAY: 3000 },
  AMCHARTS_PATH: "/visualby/amcharts/",
  ROOT_ELEMENT_ID: "app",
  TOAST_LIFETIME: 7000,
  MAX_ACTIVITIES: 20,
  WEBSITE_URLS: {
    CONTACT_PAGE_URL: "https://boardeaser.com/kontakt/",
    PRICE_PAGE_URL: "https://boardeaser.com/priser/",
  },
  PRODUCTS_REQUIRING_FINANCIALS: [
    "bjorn_lunden",
    "economy-kpi",
    "fortnox",
    "google_drive",
    "pe_accounting",
    "visma_e_accounting",
    "vitec",
    "visma_net",
    "quickbooks",
    "economy_liquidity_forecast",
  ],
  INTEGRATION_FEATURE_NAMES: [
    "bjorn_lunden",
    "dropbox",
    "fortnox",
    "google_drive",
    "pe_accounting",
    "vitec",
    "visma_e_accounting",
    "visma_net",
    "quickbooks",
  ],
  ACCOUNTING_INTEGRATION_FEATURE_NAMES: [
    "bjorn_lunden",
    "fortnox",
    "pe_accounting",
    "vitec",
    "visma_e_accounting",
    "visma_net",
    "quickbooks",
  ],
  LAYOUT: { BREAKPOINTS: { xs: 0, sm: 576, md: 768, lg: 992, xl: 1200 } },
  AVAILABLE_LOCALES: ["sv", "en"],
  DEFAULT_LOCALE: "sv",
  PASSWORD_MIN_LENGTH: 12,
  SHAREHOLDER_REGISTER: {
    SHARE_TYPES: [
      "a_shares",
      "b_shares",
      "c_shares",
      "pref_shares",
      "common_shares",
    ],
    ROUND_DECIMALS: 13,
  },
  CONTRACT: {
    CURRENCIES: ["SEK", "USD", "EUR"],
    MAIN_TYPES: ["ongoing", "limited"],
    VALUE_TYPES: ["total", "monthly"],
    FIELD_TYPES: ["text", "numeric"],
    RENEWAL_TYPES: ["no_renewal", "automatic", "manual"],
    GRAPH_COLORS: {
      ACTIVE: "#37c3a4",
      TERMINATED: "#2faa8f",
      INACTIVE: "#fbdc7e",
      INACTIVE_TERMINATED: "#f2c483",
      RUNNING: "#4ba6dd",
      INACTIVE_RUNNING: "#3086bb",
      TERMINATION_PERIOD: "#f4b87a",
    },
    TITLE_LENGTH: 25,
  },
  MEMBERSHIP_ROLES: [
    "auditor",
    "cfo",
    "ceo",
    "co_chairman",
    "secretary",
    "management",
    "contract_admin",
  ],
  MEMBERSHIP_FUNCTIONS: ["chairman", "regular", "alternate", "external"],
  MEETING: { MATERIAL: { FRESH_DOCUMENT_PERIOD: 5 } },
  ATTENDANCE_FUNCTIONS: ["chairman", "regular", "alternate", "external"],
  ORGANIZATION_TYPES: [
    "accounting_firm",
    "corporate_group",
    "foundation",
    "housing_association",
    "investment_company",
    "limited_company",
    "common_property_association",
    "nonprofit",
    "other",
  ],
  DECISION: {
    STATUSES: {
      ALL: [
        "not_started",
        "in_progress",
        "done",
        "approved",
        "down_prioritized",
        "removed",
        "archived",
        "postponed",
      ],
      ACTIVE: [
        "not_started",
        "in_progress",
        "done",
        "down_prioritized",
        "postponed",
      ],
      INACTIVE: ["approved", "removed", "archived"],
    },
  },
  INQUIRIES: { STATUSES: ["approved", "rejected", "postponed"] },
  PROJECTS: {
    STATUSES: [
      "not_started",
      "in_progress",
      "done",
      "approved",
      "down_prioritized",
      "removed",
      "archived",
      "postponed",
    ],
  },
};

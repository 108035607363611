import axios from "axios";
import { url } from "@/url-helpers";
import { handleError } from "@/utils/error-handling";

export const state = {
  contracts: null,
  receivedContracts: null,
  filteredContractIds: null, // null = no search params present
  activeFilters: [],
};

export const getters = {
  getContracts: (state) => state.contracts,

  getReceivedContracts: (state) => state.receivedContracts,

  getFilteredContracts: (state) => {
    if (state.filteredContractIds == null) {
      return state.contracts;
    }

    return state.contracts.filter((contract) =>
      state.filteredContractIds.includes(contract.id)
    );
  },

  getFilters: (state) => state.activeFilters,
};

export const actions = {
  async setContracts(context, contracts) {
    context.commit("setContracts", contracts);
  },

  async setReceivedContracts(context, contracts) {
    context.commit("setReceivedContracts", contracts);
  },

  setFilteredContractIds(context, contractIds) {
    context.commit("setFilteredContractIds", contractIds);
  },

  async removeContract(context, contract) {
    try {
      await axios.delete(url(`/contracts/${contract.id}`));

      context.commit("removeContract", contract);
    } catch (error) {
      handleError(error);
    }
  },
};

export const mutations = {
  setContracts(state, contracts) {
    state.contracts = contracts;
  },

  setReceivedContracts(state, contracts) {
    state.receivedContracts = contracts;
  },

  setFilteredContractIds(state, contractIds) {
    state.filteredContractIds = contractIds;
  },

  setFilters(state, filters) {
    state.activeFilters = filters;
  },

  removeContract(state, contract) {
    let idx = state.contracts.findIndex(
      (existingContract) => existingContract.id == contract.id
    );

    if (idx > -1) {
      state.contracts.splice(idx, 1);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

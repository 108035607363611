import axios from "axios";
import omit from "lodash/omit";
import { handleError } from "@/utils/error-handling";
import { parsePagination } from "@/helpers/pagination";

export const state = {
  user: {},

  organizations: {
    admin_panel_memberships: [],
    company_memberships: [],
  },

  notifications: [],
  notificationsFetchedAt: null,
  userActivitySessions: [],
  hasLoadedOrganizations: false,
  lastOrganizationsLoad: null,
  loadingOrganizationsTimeout: false,
};

export const getters = {
  getUser: (state) => state.user,
  getUserLocale: (state) => state.user?.locale,
  // Remove omit once slide show is implemented
  getOnboardingProgress: (state) => omit(state.user.progress, "introduction"),

  getOrganizations: (state) => state.organizations,
  hasLoadedOrganizations: (state) => state.hasLoadedOrganizations,
  lastOrganizationsLoad: (state) => state.lastOrganizationsLoad,
  getUserActivitySessions: (state) => state.userActivitySessions,
  getNotifications: (state) => state.notifications,
};

export const actions = {
  reset(context) {
    context.commit("reset");
  },

  setUser(context, user) {
    context.commit("setUser", user);
  },

  async setPreferences(context, preferences) {
    try {
      const { data } = await axios.patch("/users/preferences", {
        user: preferences,
      });

      context.commit("setUser", data);
    } catch (error) {
      handleError(error);
    }
  },

  async loadOrganizations(context) {
    try {
      const { data } = await axios.get("/users/organizations");
      context.commit("setOrganizations", data);
    } catch (error) {
      handleError(error);
    }
  },

  async fetchUnreadNotifications(context) {
    const fiveMinutes = 5 * 60 * 1000;
    if (
      context.state.notificationsFetchedAt &&
      context.state.notificationsFetchedAt > Date.now() - fiveMinutes
    ) {
      return;
    }

    context.commit("setNotifications", []);

    const fetch = async (page = 1) => {
      const { data, headers } = await axios.get("/users/notifications", {
        params: { page },
      });

      return { pagination: parsePagination(headers), notifications: data };
    };

    try {
      const { pagination, notifications } = await fetch();

      if (pagination) {
        for (let page = 2; page <= pagination.totalPages; page++) {
          const result = await fetch(page);
          notifications.push(...result.notifications);
        }
      }

      context.commit("setNotifications", notifications);
    } catch (error) {
      handleError(error);
    }
  },

  async readNotification(context, notification) {
    try {
      await axios.patch(`/users/notifications/${notification.id}`);

      context.commit("removeNotification", notification);
    } catch (error) {
      handleError(error);
    }
  },
};

export const mutations = {
  reset(state) {
    state.user = {};
  },

  setUser(state, user) {
    state.user = user;
  },

  setOrganizations(state, organizations) {
    state.organizations = organizations;
    state.hasLoadedOrganizations = true;
    state.lastOrganizationsLoad = new Date();
  },

  setLoadingOrganizationsTimeout(state, value) {
    state.loadingOrganizationsTimeout = value;
  },

  setUserActivitySessions(state, sessions) {
    state.userActivitySessions = sessions;
  },

  setUserActivitySession(state, session) {
    const index = state.userActivitySessions.findIndex(
      (existing) => existing.id === session.id
    );

    if (index !== -1) {
      state.userActivitySessions[index] = session;
    }
  },

  removeUserActivitySession(state, sessionId) {
    state.userActivitySessions = state.userActivitySessions.filter(
      (session) => session.id !== sessionId
    );
  },

  setNotifications(state, notifications) {
    state.notifications = notifications;
    state.notificationsFetchedAt = Date.now();
  },

  removeNotification(state, notification) {
    state.notifications = state.notifications.filter(
      (existing) => existing.id !== notification.id
    );
  },
};

export default {
  namespaced: true,
  sessionCached: true,
  state,
  getters,
  actions,
  mutations,
};
